import React, { useState, useEffect } from 'react';

const AppInstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      // 브라우저가 설치할 준비가 되었을 때 이벤트 발생
      console.log('handler')
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true);  // 버튼을 표시
    };
    console.log('window:::', window)
    // beforeinstallprompt 이벤트 리스너 등록
    window.addEventListener('beforeinstallprompt', handler);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    // 바탕화면에 추가 프롬프트 표시
    deferredPrompt.prompt();
    
    // 사용자의 선택을 기다림
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      console.log('사용자가 바탕화면에 추가를 수락했습니다.');
    } else {
      console.log('사용자가 바탕화면에 추가를 거절했습니다.');
    }
    
    // 프롬프트를 재사용하지 않도록 초기화
    setDeferredPrompt(null);
    setIsVisible(false); // 버튼 숨기기
  };

  if (!isVisible) {
    return null; // 버튼이 보이지 않음
  }

  return (
    <button type="button" className='install-button' onClick={handleInstallClick}>
      바로가기<br />추가
    </button>
  );
};

export default AppInstallButton;
